<template>
  <redness-locations-template
    :gender="gender"
    :redness-limited-to-pimples="rednessLimitedToPimples"
    :redness-locations="rednessLocations"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import RednessLocationsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/redness-locations/RednessLocationsTemplate';

import { no } from '@/modules/questionnaire/api/helpers';

const fieldsToReset = ['inflammationDiscomfort'];
const { field, requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [
  ...fieldsToReset.map(field),
  field('gender'),
  requiredField('rednessLimitedToPimples'),
  requiredArrayField('rednessLocations')
];

export default {
  name: 'RednessLocations',
  components: {
    RednessLocationsTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    rednessLimitedToPimples(newValue, oldValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      if (!oldValue && this.rednessLocations.length > 0) {
        this.showNextStep();
      } else {
        this.scrollTo('#facial-redness-locations');
      }
    }
  }
};
</script>
